"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utilities_1 = require("./utilities");
var getResponsiveValue_1 = require("./utilities/getResponsiveValue");
var getMemoizedMapHashKey = function (dimensions, themeKey, property, value) {
    return "".concat(dimensions === null || dimensions === void 0 ? void 0 : dimensions.height, "x").concat(dimensions === null || dimensions === void 0 ? void 0 : dimensions.width, "-").concat(themeKey, "-").concat(property, "-").concat(value);
};
var memoizedThemes = new WeakMap();
var createRestyleFunction = function (_a) {
    var property = _a.property, transform = _a.transform, styleProperty = _a.styleProperty, themeKey = _a.themeKey;
    var styleProp = styleProperty || property.toString();
    var func = function (props, _a) {
        var _b, _c;
        var theme = _a.theme, dimensions = _a.dimensions;
        if (memoizedThemes.get(theme) == null) {
            memoizedThemes.set(theme, {});
        }
        var memoizedMapHashKey = (function () {
            if (themeKey &&
                property &&
                props[property] &&
                typeof themeKey === 'string' &&
                typeof property === 'string') {
                return getMemoizedMapHashKey(dimensions, String(themeKey), String(property), String(props[property]));
            }
            else {
                return null;
            }
        })();
        if (memoizedMapHashKey != null) {
            var memoizedValue = memoizedThemes.get(theme)[memoizedMapHashKey];
            if (memoizedValue != null) {
                return memoizedValue;
            }
        }
        var value = (function () {
            if (isResponsiveTheme(theme) && dimensions) {
                return (0, getResponsiveValue_1.getResponsiveValue)(props[property], {
                    theme: theme,
                    dimensions: dimensions,
                    themeKey: themeKey,
                    transform: transform,
                });
            }
            else {
                return (0, utilities_1.getThemeValue)(props[property], { theme: theme, themeKey: themeKey, transform: transform });
            }
        })();
        if (value === undefined)
            return {};
        if (memoizedMapHashKey != null) {
            memoizedThemes.get(theme)[memoizedMapHashKey] = (_b = {},
                _b[styleProp] = value,
                _b);
            return memoizedThemes.get(theme)[memoizedMapHashKey];
        }
        return _c = {},
            _c[styleProp] = value,
            _c;
    };
    return {
        property: property,
        themeKey: themeKey,
        variant: false,
        func: func,
    };
};
function isResponsiveTheme(theme) {
    if (theme.breakpoints !== undefined) {
        return true;
    }
    return false;
}
exports.default = createRestyleFunction;
